<template>
    <div>
        <HRetribusi imageLogo="imageLogo" />

        <div
            v-if="
                user.roles[0].name === 'Maintenance' ||
                    user.roles[0].name === 'Administrator'
            "
        >
            <!-- begin row -->
            <div class="row py-3" v-if="detail">
                <!-- begin col-3 -->
                <div
                    class="col-xl-4 col-md-6 main my-2"
                    @click="dataView(0)"
                    style="cursor: pointer;"
                >
                    <div
                        class="widget widget-stats bg-orange cust-shadow"
                        style="height: 100%"
                    >
                        <div class="stats-icon">
                            <i class="fa fa-users"></i>
                        </div>
                        <div class="stats-info">
                            <h4>TOTAL REGISTRASI</h4>
                            <p v-if="detail">{{ detail.count_all }}</p>
                        </div>
                    </div>
                </div>
                <!-- end col-3 -->
                <!-- begin col-3 -->
                    <div
                        class="col-xl-4 col-md-6 main my-2"
						v-for="(item, index) in detail.count_by_status_registrasi" :key="index"
                        @click="dataView(item.status_id)"
                    >
                        <div
                            :class="classFunction"
                            style="height: 100%"
                        >
                            <div class="stats-icon">
                                <i class="fa fa-save"></i>
                            </div>
                            <div class="stats-info">
                                <h4>{{ item.nama.toUpperCase() }}</h4>
                                <p>{{ item.jumlah_data }}</p>
                            </div>
                        </div>
                    </div>
            </div>
			<div v-else>
				<b-card>
					<h3>SELAMAT DATANG DIAPLIKASI PAJAK</h3>
				</b-card>
			</div>
            <!-- end row -->
            <!-- begin panel -->
            <!-- <panel title="Line Chart" bodyClass="p-0">
				<p class="mb-0 p-15">
					JavaScript Line Charts are a typical pictorial representation that depicts trends and behaviors over time. It is represented by a series of data points connected with a line.
				</p>
				<apexchart type="line" :options="lineChart.options" :series="lineChart.series"></apexchart>
			</panel> -->
            <!-- end panel -->
            <!-- Panel end here -->
            <!-- end Panel -->
        </div>
        <div v-else>
            <DashboardPajak />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import Vue from "vue";
import Logo from "@/assets/images/logoSumut.png";
import PageOptions from "@/config/PageOptions.vue";
import HRetribusi from "@/components/header/HRetribusi.vue";
import axios from "axios";
import moment from "moment";
import DashboardPajak from "@/components/subjek_pajak/user_dashboard/DashboardPajak.vue";

Vue.prototype.moment = moment;

export default {
    data() {
        return {
            imageLogo: Logo,
            perPage: 10,
            detail: null,
            user: JSON.parse(localStorage.getItem("user")),
			classFunction: {
				'widget': true, 
				'widget-stats': true,
				'bg-secondary': true,
				'cust-shadow': true
			}
        };
    },
    components: {
        HRetribusi,
        DashboardPajak,
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
    },
    created() {
        PageOptions.pageEmpty = false;
        document.body.className = "";
		this.getData();
    },
    mounted() {
        if (this.user.roles[0].name === "Wajib Pajak") {
            // console.log(this.$route.name)
            if (this.$route.name === "Dashboard") {
                this.$router.push({ name: "StepProfil" });
            }
        }
    },
    methods: {
        viewAll() {
            this.$router.push("/admin/npwpd");
        },
        dataView(cond) {
            localStorage.setItem("statusRegistrasi", cond);
            this.$router.push("/admin/npwpd");
        },
        // semua data
        getData() {
            axios
                .get("/api/wajib-pajak/npwpd/getcount/statusregistrasi")
                .then((response) => {
                    const items = response.data.data;
					this.detail = items
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
.main:hover .cust-shadow {
    box-shadow: 3px 3px 7px black;
    transition: all 0.5s ease;
    cursor: pointer;
}
</style>
